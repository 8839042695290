<template>
  <svg viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7504 9.86989V11.6199C13.7511 11.7824 13.7154 11.9432 13.6457 12.092C13.576 12.2409 13.4737 12.3745 13.3454 12.4843C13.2172 12.5941 13.0657 12.6778 12.9009 12.7298C12.736 12.7819 12.5612 12.8012 12.3879 12.7866C10.4647 12.5915 8.61726 11.9781 6.99414 10.9957C5.48403 10.1001 4.20372 8.90516 3.24414 7.49573C2.18787 5.97393 1.53054 4.24131 1.32539 2.43823C1.30977 2.27692 1.33031 2.11434 1.3857 1.96084C1.44109 1.80735 1.53012 1.6663 1.64711 1.54667C1.76411 1.42705 1.90651 1.33147 2.06525 1.26603C2.224 1.20059 2.3956 1.16671 2.56914 1.16656H4.44414C4.74745 1.16377 5.04151 1.26402 5.27149 1.44862C5.50147 1.63322 5.65168 1.88957 5.69414 2.16989C5.77327 2.72993 5.92004 3.27982 6.13164 3.80906C6.21573 4.01785 6.23392 4.24476 6.18408 4.46291C6.13423 4.68105 6.01842 4.88129 5.85039 5.03989L5.05664 5.78073C5.94636 7.24113 7.24192 8.45032 8.80664 9.28073L9.60039 8.53989C9.77032 8.38306 9.98486 8.27497 10.2186 8.22845C10.4523 8.18192 10.6954 8.19891 10.9191 8.27739C11.4862 8.47488 12.0753 8.61186 12.6754 8.68573C12.979 8.7257 13.2563 8.86843 13.4545 9.08677C13.6527 9.3051 13.758 9.58381 13.7504 9.86989Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
